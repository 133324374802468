<template>
    <div class="products-page">
        <base-nav />

        <section class="d-flex overflow-hidden mb-4">
            <b-container class="product-container">
                <div class="row product-wrapper">
                    <div class="col-lg-12">
                        <div class="airaProduct_logo">
                                    <img
                                        loading="lazy"
                                        src="@/assets/img/product/airaTrack-pro/airaTrack-pro-logo.png"
                                        title="AiraTrack" 
                                        :alt="$t('Products.AiraTrack.description')">
                        </div>
                    </div>
                    <div class="col-lg-12" style="height: 750px;border-bottom: 2px solid #9DACB9;">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="product-image">
                                            <img src="@/assets/img/product/airaTrack-pro/airaTrack-Pro-1.png"
                                                loading="lazy"
                                                :title="$t('Products.AiraTrack.description')"
                                                :alt="$t('Products.AiraTrack.description')">
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="product-small-image">
                                            <img src="@/assets/img/product/airaTrack-pro/airaTrack-investigation-01.png"
                                            loading="lazy"
                                            :title="$t('Products.AiraTrack.description')"
                                                :alt="$t('Products.AiraTrack.description')">
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="product-small-image">
                                            <img src="@/assets/img/product/airaTrack-pro/airaTrack-investigation-02.png"
                                            loading="lazy"
                                            :title="$t('Products.AiraTrack.description')"
                                                :alt="$t('Products.AiraTrack.description')">
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="product-small-image">
                                            <img src="@/assets/img/product/airaTrack-pro/airaTrack-investigation-03.png"
                                            loading="lazy"
                                            :title="$t('Products.AiraTrack.description')"
                                                :alt="$t('Products.AiraTrack.description')">
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="product-small-image" style="overflow: hidden;max-width: 185px;max-height: 152px;">
                                            <img  style="width:200px;height:115px" src="@/assets/img/product/airaTrack-pro/airaTrack-investigation-04.png"
                                            loading="lazy"
                                            :title="$t('Products.AiraTrack.description')"
                                                :alt="$t('Products.AiraTrack.description')">
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-lg-6">
                                <h2 class="wdn-text-hidden">airaTrack-pro</h2>
                                <h3 class="product-description-title">{{ airaTrack_pro.title }}</h3>
                                <div class="product-description">
                                    <p style="font-size:20px">{{airaTrack_pro.description}}</p>
                                </div>
                                <router-link to="/contactUs" >
                                    <button class="call-to-act">
                                        Inquire Now
                                    </button>
                                </router-link>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12"  style="height: 700px;border-bottom: 2px solid #9DACB9;">
                        <div class="row">
                            <div class="col-lg-12">
                                <p class="cross-cam-title">{{airaTrack_pro.feature_cross_cams.title}}</p>
                            </div>
                            <div class="col-lg-6">
                                <ul class="mb-0">
                                    <h2 class="product-key-features">{{airaTrack_pro.feature_cross_cams.feature_title}}</h2>
                                    <li style="margin-left:48px">
                                        <p class="product-key-features-description">
                                            <span class="product-key-features-title">{{airaTrack_pro.feature_cross_cams.key1_title}}</span>
                                            <span>{{airaTrack_pro.feature_cross_cams.key1_description}}</span>
                                        </p>
                                    </li>
                                    <li style="margin-left:48px">
                                        <p class="product-key-features-description">
                                            <span class="product-key-features-title">{{airaTrack_pro.feature_cross_cams.key2_title}}</span>
                                            <span>{{airaTrack_pro.feature_cross_cams.key2_description}}</span>
                                        </p>
                                    </li>
                                    <li style="margin-left:48px">
                                        <p class="product-key-features-description">
                                            <span class="product-key-features-title">{{airaTrack_pro.feature_cross_cams.key3_title}}</span>
                                            <span>{{airaTrack_pro.feature_cross_cams.key3_description}}</span>
                                        </p>
                                    </li>
                                </ul>
                                
                            </div>
                            <div class="col-lg-6">
                                <div class="product-image">
                                            <img src="@/assets/img/product/airaTrack-pro/airaTrack-investigation-03.png"
                                                loading="lazy"
                                                :title="$t('Products.AiraTrack.description')"
                                                :alt="$t('Products.AiraTrack.description')">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12" style="height: 900px;border-bottom: 2px solid #9DACB9;">
                        <div class="row">
                            <div class="col-lg-12">
                                <ul class="mb-0">
                                    <h2 class="product-key-features">{{airaTrack_pro.feature_Structure.key1_title}}</h2>
                                    <li style="margin-left:48px;list-style: none;">
                                        <p class="product-key-features-description" style="white-space: pre-wrap;">
                                            <span>{{airaTrack_pro.feature_Structure.key1_description}}</span>
                                        </p>
                                    </li>
                                </ul>

                            </div>
                            <div class="col=lg-12">
                                <div class="structure-image">
                                            <img src="@/assets/img/product/airaTrack-pro/airaTrack-pro-structure.png"
                                                loading="lazy"
                                                :title="$t('Products.AiraTrack.description')"
                                                :alt="$t('Products.AiraTrack.description')">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12" style="margin-top:30px;height: 700px;border-bottom: 2px solid #9DACB9;">
                        <div class="row" style="padding-left:40px">
                            <div class="col-lg-6">
                                <h2 class="wdn-text-hidden">airaTrack Pro</h2>
                                <h3 class="product-description-title">{{ airaTrack_pro.feature_Design.key1_title }}</h3>
                                <div class="product-description">
                                    <p style="font-size:20px">{{airaTrack_pro.feature_Design.key1_description}}</p>
                                </div>
                            </div>
                            <div class="col-lg-6" style="margin-top: 70px;">
                                <div class="product-image">
                                            <img src="@/assets/img/product/airaTrack-pro/airaTrack-investigation-04.png"
                                                loading="lazy"
                                                :title="$t('Products.AiraTrack.description')"
                                                :alt="$t('Products.AiraTrack.description')">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12" style="margin-top:30px;height: 800px;border-bottom: 2px solid #9DACB9;">
                        <div class="row" style="padding-left:40px">
                            <div class="col-lg-12" style="margin-top:10px">
                                <h3 class="product-description-title">{{ airaTrack_pro.feature_Specification.key1_title }}</h3>
                            </div>
                            <div class="col-lg-12" style="margin-top:30px;margin-bottom:30px">
                                <div class="spec-box">
                                        {{ airaTrack_pro.feature_Specification.spec_title1 }}
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <ul style="padding:0px">
                                    <li class="spec-point-title">{{ airaTrack_pro.feature_Specification.key1_title }}</li>
                                    <li class="spec-point-title">{{ airaTrack_pro.feature_Specification.key2_title }}</li>
                                    <li class="spec-point-title"><br/></li>
                                    <li class="spec-point-title">{{ airaTrack_pro.feature_Specification.key3_title }}</li>
                                    <li class="spec-point-title">{{ airaTrack_pro.feature_Specification.key4_title }}</li>
                                </ul>
                            </div>
                            <div class="col-lg-9">
                                <ul style="padding:0px">
                                    <li class="spec-point">{{ airaTrack_pro.feature_Specification.key1_description }}</li>
                                    <li class="spec-point">{{ airaTrack_pro.feature_Specification.key2_description }}</li>
                                    <!-- <li class="spec-point"></li> -->
                                    <li class="spec-point">{{ airaTrack_pro.feature_Specification.key3_description }}</li>
                                    <li class="spec-point">{{ airaTrack_pro.feature_Specification.key4_description }}</li>
                                </ul>
                            </div>
                            <div class="col-lg-12" style="margin-top:30px;margin-bottom:30px">
                                <div class="spec-box">
                                        {{ airaTrack_pro.feature_Specification.spec_title2 }}
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <ul style="padding:0px">
                                    <li class="spec-point-title">{{ airaTrack_pro.feature_Specification.key5_title }}</li>
                                    <li class="spec-point-title">{{ airaTrack_pro.feature_Specification.key6_title }}</li>
                                    <li class="spec-point-title">{{ airaTrack_pro.feature_Specification.key7_title }}</li>
                                    <li class="spec-point-title">{{ airaTrack_pro.feature_Specification.key8_title }}</li>
                                    <li class="spec-point-title">{{ airaTrack_pro.feature_Specification.key9_title }}</li>
                                    <li class="spec-point-title">{{ airaTrack_pro.feature_Specification.key10_title }}</li>
                                    <li class="spec-point-title">{{ airaTrack_pro.feature_Specification.key11_title }}</li>
                                </ul>
                            </div>
                            <div class="col-lg-9">
                                <ul style="padding:0px">
                                    <li class="spec-point">{{ airaTrack_pro.feature_Specification.key5_description }}</li>
                                    <li class="spec-point">{{ airaTrack_pro.feature_Specification.key6_description }}</li>
                                    <li class="spec-point">{{ airaTrack_pro.feature_Specification.key7_description }}</li>
                                    <li class="spec-point">{{ airaTrack_pro.feature_Specification.key8_description }}</li>
                                    <li class="spec-point">{{ airaTrack_pro.feature_Specification.key9_description }}</li>
                                    <li class="spec-point">{{ airaTrack_pro.feature_Specification.key10_description }}</li>
                                    <li class="spec-point">{{ airaTrack_pro.feature_Specification.key11_description }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12" style="margin-top:30px;height: 350px;border-bottom: 2px solid #9DACB9;">
                        <div class="row" style="padding-left:40px">
                            <div class="col-lg-12" style="margin-top:10px">
                                <h3 class="product-description-title">{{ airaTrack_pro.feature_Document.key1_title }}</h3>
                            </div>
                            <div class="col-lg-5">
                                <ul style="padding:0px">
                                    <li class="spec-point-title">{{ airaTrack_pro.feature_Document.key1_title }}</li>
                                    <li class="spec-point">{{ airaTrack_pro.feature_Document.key1_description }}</li>
                                    <li class="spec-point-title" style="margin-top:30px">{{ airaTrack_pro.feature_Document.key2_title }}</li>
                                    <li class="spec-point">{{ airaTrack_pro.feature_Document.key2_description }}</li>
                                    
                                </ul>
                            </div>
                            <div class="col-lg-7">
                                <ul style="padding:0px">
                                    <button class="call-to-act download-box" @click="downloadFile('airaTrack-datasheet')" >
                                        DOWNLOAD
                                    </button>
                                    <button class="call-to-act download-box" style="margin-top:51px" @click="downloadFile('airaTrack-usermanual')" >
                                        DOWNLOAD
                                    </button>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12" style="margin-top:30px;height: 850px;">
                        <div class="row">
                            <div class="col-lg-12" style="margin-top:10px;padding-left:40px">
                                <h3 class="product-description-title">{{ airaTrack_pro.feature_video.title }}</h3>
                            </div>
                            <div class="col-lg-12">
                                <div class="d-flex" style="justify-content: center;margin-top: 40px;">
                                    <iframe  width="1120" height="630" :src="airaTrack_pro.feature_video.video_src" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                                    </iframe>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="d-flex" style="justify-content: center;margin-top: 60px;">
                                    <router-link to="/contactUs" >
                                        <button class="call-to-act">
                                            Inquire Now
                                        </button>
                                    </router-link>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                        
                    
                </div>
            </b-container>
        </section>
        
        <base-footer />
    </div>
</template>

<script>

const defaultState = () => {
        return {
            airaTrack_pro: {
                
                title: "airaTrack Pro",
                description: `With AIRAs patented AI technology, airaTrack is capable of detecting person figure, cloth color, accessory, face, and generates a unique personal landmark identifier.
                
airaTrack can effectively match the target person across multiple cameras and VMS video archives and display the path of the target person and incidences with time occurrence. It enormously shortens the time spent on looking for a person in this field, and greatly increases field safety.`,
                feature_cross_cams: {
                    title : `‘’Track person across cameras’’`,
                    feature_title: "Feature highlight",
                    key1_title:"Patented multi-attribute index:",
                    key1_description: "airaTrack can match the target person using multi-attribute index to increase search coverage",
                    key2_title:"Search across multiple cameras: ",
                    key2_description: "airaTrack’s key feature is to search the target person across multiple cameras and VMS video archive; it overcomes the difficulty on matching person under different camera’s view angle and lighting condition",
                    key3_title:"De-identification:",
                    key3_description: "airaTrack can generate multi-attribute index without enrolling person in advance; user can upload a photo or pick a person from the video to start matching the target person"
                },
                feature_Structure: {
                    key1_title: "Engineering",
                    key1_description: `AIRA’s patented AI model focuses on person attributes, including person figure, cloth color, accessory, face and generates a unique personal landmark identifier.  AIRA also develops its own inference engine optimizing for Intel CPU, therefore, AIRA’s solution can run on Intel CPU only (without GPU card); this engineering design significantly reduces the cost on hardware sizing and cost.

airaTrack can match person across multiple cameras, it overcomes different video quality and lighting conditions from multiple cameras and performs accurate person matching using AIRA’s AI model.`
                },
                feature_Design:  {
                    key1_title : "Design",
                    key1_description: `It’s a tremendous effort to look for a person in a field when there are multiple cameras deployed, especially when you need to track the target person’s in the field.  Operator needs to review camera video footage one-by-one, and add bookmarks at the time and camera of this incident, then process the video footage of the incidents for evidence later on.


airaTrack provides an efficient way to track the target person; it uses face or body shape landmarks to match the person across multiple camera and VMS video archives, bookmark the incidents in timeline, and add to evidence file with simple clicks.
`
                },
                feature_Specification: {
                    title: "Specification",
                    spec_title1 : "Software",
                    key1_title: "Video Source",
                    key1_description:"Support 16 channels RTSP protocol, including IP camera, VMS, DVR, NVR",
                    key2_title: "Object Matching",
                    key2_description:`Object size larger than 100x100 pixel, target person can come from object index, album or photo uploaded manually (supports JPG and PNG format)`,
                    key3_title: "Object Index Search Engine",
                    key3_description: "Takes 1 second to search 10,000 objects in the database",
                    key4_title: "VMS Search Engine",
                    key4_description: "Takes 10 minutes to search 1-hour video archive (1080P)",
                    spec_title2 : "Hardware",
                    key5_title: "Model",
                    key5_description:"TRK-6200",
                    key6_title: "Operation System",
                    key6_description:"Ubuntu 20.04 LTS",
                    key7_title: "CPU",
                    key7_description: "Intel Xeon E2226G",
                    key8_title: "RAM Size",
                    key8_description: "DDR4 32 GB",
                    key9_title: "Storage",
                    key9_description:"1TB SSD",
                    key10_title: "Dimensions(inches)",
                    key10_description:"14.1 x 7.4 x 14.4",
                    key11_title: "Weight (pounds)",
                    key11_description: "11.9"
                },
                feature_Document: {
                    title: "Documentation",
                    key1_title: "Datasheet",
                    key1_description: "airaTrack Pro Datasheet 2022.09.08 ver.1",
                    key2_title: "User Manual",
                    key2_description: "airaTrack Pro User Manual 2022.09.08 ver.1",
                },
                feature_video:{
                    title: "Video",
                    video_src: "https://www.youtube.com/embed/_wxqYneHgZg"
                }
            }



        } 
    }

export default {
    name: 'airaTrackPro',


    metaInfo() {
        return {
            title: this.$t('Nav_products'),
            meta: [
                {
                    name: 'description',
                    content: `airaFace - ${this.$t('Products.AiraFace.description')} , airaTablet - ${this.$t('Products.AiraTablet.description')}, airaTrack - ${this.$t('Products.AiraTrack.description')}, airaTablet - ${this.$t('Products.AiraTablet_mini.description')}`
                }, {
                    name: 'keywords',
                    content: `airaFace - ${this.$t('Products.AiraFace.description')} , airaTablet - ${this.$t('Products.AiraTablet.description')},airaTrack - ${this.$t('Products.AiraTrack.description')}, airaTablet - ${this.$t('Products.AiraTablet_mini.description')}`
                }, {
                    name: 'author',
                    content: this.$t('Head_author')
                }, {
                    name: 'copyright',
                    content: this.$t('Head_copyright')
                },
            ]
        }
    },

    data() {
        
        return Object.assign({}, defaultState())
    },

    computed: {},

    created() {
        window.scrollTo(0,0);
    },


    methods: {
        downloadFile(productName) {
            const a = document.createElement('a');

            let fileName = "";
            switch (productName) {
                case 'airaTrack-datasheet': {
                    fileName = "airaTrack-datasheet.png"
                }break;
                case 'airaTrack-usermanual': {
                    fileName = "airaTrack-User-Manual-English-20220315.pdf"
                }break;
            }


            const filePath = `/download_files/${fileName}`;

            a.href = filePath;
            a.download = fileName;
            a.click()
            a.remove()
        }
    }
}
</script>

<style lang="scss" scoped>
.products-page{
    background: #fff;
}

.product-wrapper{
    margin-right: -15px;
    margin-left: -15px;
    padding: 40px;
    // box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    // border: solid 1px #ebebeb;
    background-color: #fff;
    margin-bottom: 60px;
}

.product-container {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 64px;
    max-height: 100%;
}

.product-image{
    margin-top: 30px;
    margin-right: 40px;
    max-width: 890px;
    border: solid 1px #d0d0d0;
    // background: #fff;
    max-height: 500px;
    width: 100%;
}



.product-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}


.product-small-image{
    margin-top: 25px;
    // max-width: 150px;
    // border: solid 1px #d0d0d0;
    // background: #fff;
    max-height: 81px;
    width: 100%;
}

.product-small-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.airaProduct_logo{
    height: 60px;
    flex: 0 0 100%;
    max-width: 100%;
}

.airaProduct_logo img {
    width: auto;
    height: 100%;
}

.product-description-title {
    font-size: 30px;
    margin-top: 26px;
}

.product-description{
    white-space: pre-wrap;
    width: 690px;
    height: 310px;
    font-size: 25px;
    font-family: Helvetica;
    font-weight: 100;
    line-height: 35px;
    margin-top: 25px;
    color: #293845;
}
.cross-cam-title {
    position: relative;
    font-size: 40px;
    font-family: Helvetica;
    font-weight: bold;
    line-height: 35px;
    color: #E77C30;
    text-align: center;
    margin-top: 60px;
}

.call-to-act {
    cursor: pointer;
    border: unset;
    background-color: #2C88D9;
    color: #ffffff;
    height: 55px;
    width: 250px;
    border-radius:  5px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    transition: 0.4s;
    &:hover {
        filter: brightness(1.2);
        color: #000000;
    }
}


.product-key-features{
    font-size: 30px;
    margin-top: 24px;
    color: #2c2c2c;
}
.product-key-features-title{
    position: relative;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: bold;
}
.product-key-features-description{
    position: relative;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 100;
}


.structure-image{
    margin-top: 30px;
    margin-left: 88px;
    max-width: 1400px;
    max-height: 500px;
    width: 100%;
}



.structure-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.spec-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 55px;
    border: 3px solid #AED1F0;
    font-size: 25px;
    font-family: Helvetica;
    font-weight: 300;
    color: #3B91DC;
}

.download-box {
    display: block;
    margin: auto;
    font-size: 20px;
    height: 46px;
}


.spec-point-title {
    list-style: none;
    font-size: 20px;
    font-family: Helvetica;
    font-weight: bold;
}

.spec-point {
    list-style: none;
    font-size: 20px;
    font-family: Helvetica;
    font-weight: 300;
    color: #7A8997;
}

.product-info > *{
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 20px;
}

ul.features {
    border-right: 1px solid #e5e4e4;
}

ul.features li {
    padding: 2px 0;
    color: #555;
    font-weight: 300;
}

.cursor-pointer {
    cursor: pointer;
}

.pdf-icon{
    width: 100%;
    max-width: 88px;
    height: auto;
}

.download-file-wrapper {
    align-items: flex-start;
}

.download-title{
    font-size: 17px;
    color: #2c2c2c;
    padding-top: 30px;
}

.file-name{
    color: #2c2c2c;
    font-size: 17px;
    font-weight: 300;
    transition: color ease .2s;
    word-break: break-all;
    margin-bottom: 0;
    &:hover {
        color: #4290cc;
    }
}

@media screen and (max-width: 1024px) {
    .product-image {
        flex: 0 0 250px;
        max-width: 250px;
        max-height: 415px;
    }

    .download-title{
        padding-top: 8px;
    }
}

@media screen and (max-width: 812px) {
    .product-wrapper {
        flex-wrap: wrap;
    }

    .product-image {
        margin: 0 auto;
        border: none;
        flex: 0 0 auto;
        max-width: 350px;
    }

    .airaProduct_logo {
        text-align: center;
        margin-top: 20px;
        height: 50px;
    }
}

@media screen and (max-width: 576px) {
    .product-info {
        flex-wrap: wrap;
    }

    .product-info > *{
        flex: 0 0 100%;
        max-width: 100%;
    }

    ul.features {
        border: none;
    }

    .download-file-wrapper {
        align-items: center;
        padding-left: 0;
        margin-top: 30px;
    }

    .product-wrapper{
        padding: 20px;
    }
}

</style>